import { Component, OnInit } from '@angular/core';
import { Term } from './term';
import { TermsService } from './terms.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
    constructor(
        private trmService: TermsService
    ) {}
    term: Term;
    ngOnInit() {
        this.getTerms();
    }
    getTerms() {
        this.trmService
            .getTerms()
            .subscribe(
                term => {
                    this.term = term[0];
                },
                err => console.error(err),
                () => console.log('done loading terms')
            );
    }
}
