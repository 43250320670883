import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {ContactComponent} from './components/contact/contact.component';
import {EmploymentComponent} from './components/employment/employment.component';
import {QuestionsComponent} from './components/questions/questions.component';
import {CommentsService} from './components/home/comments.service';
import {QuestionsService} from './components/questions/questions.service';
import {DownloadComponent} from './components/download/download.component';
import {TermsComponent } from './components/terms/terms.component';
import {TermsService} from './components/terms/terms.service';
import {EmployService} from './components/employment/employ.service';
import {HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ContactComponent,
        EmploymentComponent,
        QuestionsComponent,
        DownloadComponent,
        TermsComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule.forRoot([
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'terms',
                component: TermsComponent
            },
            {
                path: 'questions',
                component: QuestionsComponent
            },
            {
                path: 'employment',
                component: EmploymentComponent
            },
            {
                path: 'download',
                component: DownloadComponent
            },
            {
                path: 'contact',
                component: ContactComponent
            }
        ])
    ],
    providers: [
        CommentsService,
        QuestionsService,
        TermsService,
        EmployService,
        {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
