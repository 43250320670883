import { Component, OnInit } from '@angular/core';
import {EmployService} from './employ.service';
import {Employ} from './employ';

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css']
})

export class EmploymentComponent implements OnInit {
    constructor(
        private empService: EmployService
    ) {}

    employ: Employ;
    ngOnInit() {
        this.getEmploy();
    }

    getEmploy() {
        this.empService
            .getEmploy()
            .subscribe(
                employ => {
                    this.employ = employ[0];
                },
                err => console.error(err),
                () => console.log('done loading employment')
        );
    }
}
