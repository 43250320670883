import { Component, OnInit } from '@angular/core';
import { CommentsService } from './comments.service';
import { Comment } from './comment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(
      private comService: CommentsService
  ) {}
    comment: Comment;
    ngOnInit() {
        this.getComment();
    }
    getComment() {
        this.comService
            .getComment()
            .subscribe(
            comment => {
                this.comment = comment[0];
            },
            err => console.error(err),
            () => console.log('done loading comments')
        );
    }
}
