import { Component, OnInit } from '@angular/core';
import { QuestionsService } from './questions.service';
import { Question } from './question';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})

export class QuestionsComponent implements OnInit {
    constructor(
        private quesService: QuestionsService
    ) {}
    question: Question;
    ngOnInit() {
        this.getQuestion();
    }
    getQuestion() {
        this.quesService
            .getQuestion()
            .subscribe(
                question => {
                    this.question = question[0];
                },
                err => console.error(err),
                () => console.log('done loading question')
            );
    }
}
